<template>
  <div class="mb-1">
    <b-tabs>
      <!-- SECTION Fare & Benefits -->
      <b-tab
        :title-link-class="isMobileView ? 'px-25 mr-75' : ''"
      >
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>{{ $t('flight.fare&benefit') }}</span>
        </template>

        <!-- NOTE list group -->
        <b-list-group
          class=" cursor-pointer"
          flush
        >
          <div
            v-for="(fareItem, i) in getFareOptions(trip.fareOptions, ['VN1A_MT', 'VN1A'].includes(trip.source))"
            :key="i"
          >
            <!-- v-ripple.200="'rgba(47, 165, 229, 0.2)'" -->
            <b-list-group-item
              v-show="isRenderFareArray.length ? isRenderFareArray[i] : true"
              :class="`border-bottom ${(currentBreakPoint !== 'xl') ? 'font-small-3 px-25' : ''}`"
              :active="ticketSelected === i"
              :disabled="fareItem.seatAvailable === 0"
              @click="handleClickTicket(fareItem, i)"
            >
              <div :class="`d-flex pb-50`">
                <b-col
                  class="d-flex p-0 align-items-center justify-content-between"
                  cols="1"
                  md="1"
                >
                  <feather-icon
                    v-if="ticketSelected !== i"
                    icon="CircleIcon"
                    size="21"
                    class="mt-50"
                  />

                  <feather-icon
                    v-if="ticketSelected === i"
                    icon="CheckCircleIcon"
                    size="21"
                    class="text-success mt-50"
                  />
                </b-col>

                <!-- SECTION Fare -->
                <b-col
                  cols="11"
                  md="11"
                  class="font-weight-bold pl-0"
                >
                  <!-- ANCHOR - Chi tiết giá -->
                  <b-row
                    class="mb-1"
                  >
                    <!-- ANCHOR Loại chỗ  -->
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <div class="font-weight-bolderp">
                        <!-- {{ ['QH', 'VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass }} -->
                        <span
                          v-if="trip.source !== 'VJ' && fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass)"
                        >
                          {{
                            fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source)
                              ? fareItem.fareType
                              : fareItem.groupClass).cabinName
                          }}
                        </span>
                        <span>
                          {{ ['QH', 'VU', '1S', '1S_CTRL'].includes(trip.source)
                            ? '' : `${(fareItem.groupClass)}`
                          }} ({{ fareItem.bookingClass }})
                        </span>
                      </div>
                    </b-col>

                    <!-- giá -->
                    <b-col
                      cols="12"
                      md="8"
                    >
                      <div
                        v-for="(priceItem, indexPrice) in resolveFarePrice(flightSearch, fareItem)"
                        :key="indexPrice"
                      >
                        <b-row v-if="priceItem.price > 0">
                          <b-col
                            cols="3"
                            class="text-center p-0"
                          >
                            <span>{{ priceItem.title }}</span>
                            <!-- <span> x {{ priceItem.quantity }}</span> -->
                          </b-col>

                          <b-col
                            class="text-right pl-0"
                            cols="3"
                          >
                            <span
                              v-b-tooltip.hover.v-primary
                              class="text-nowrap"
                              badge-classes="badge-info badge-glow"
                              title="Giá vé"
                            >
                              {{ formatCurrency(priceItem.fare) }}
                            </span>
                          </b-col>

                          <b-col
                            class="text-right pl-0"
                            cols="3"
                          >
                            <span
                              v-b-tooltip.hover.v-primary
                              class="text-nowrap"
                              badge-classes="badge-info badge-glow text-nowrap"
                              :title="`Thuế: ${formatCurrency(priceItem.tax)} - Phí: ${formatCurrency(priceItem.surcharge)} - Chiết khấu: ${formatCurrency(priceItem.discount)}`"
                            >
                              {{ formatCurrency(priceItem.tax + priceItem.surcharge - priceItem.discount) }}
                            </span>
                          </b-col>

                          <b-col
                            cols="3"
                            class="text-right font-weight-bolder pl-0"
                          >
                            <span
                              v-b-tooltip.hover.v-primary
                              class="text-nowrap"
                              badge-classes="badge-info badge-glow"
                              title="Tổng tiền"
                            >
                              {{ formatCurrency(priceItem.priceOne) }}
                            </span>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>

                  <!-- ANCHOR - Ghế, hành lý -->
                  <b-row class="mx-0">
                    <!-- ANCHOR - Số chỗ -->
                    <div class="d-flex align-items-center justify-content-center px-md-1">
                      <span class="mr-1 text-nowrap">
                        {{ $t('flight.seatAvailable') }}: <b :class="fareItem.seatAvailable > 0 ? 'text-airline fw-700' : 'text-danger fw-700'">{{ fareItem.seatAvailable > 0 ? `${fareItem.seatAvailable}` : 'HẾT CHỖ' }}</b>
                      </span>

                      <!-- ANCHOR - Hành lý -->
                      <span>
                        {{ $t('flight.Baggage') }}:
                        <b v-if="trip.source === '1G'">
                          {{ `${fareItem.baggages[0].numBaggagePieces > 0 ? fareItem.baggages[0].numBaggagePieces +
                            'PC' : ''}${fareItem.baggages[0].numBaggagePieces > 1 ? 's' : ''}
                                                            ${fareItem.baggages[0].numBaggagePieces && fareItem.baggages[0].descriptions.length ? ' + '
                          : ''} ${fareItem.baggages[0].descriptions.length ? fareItem.baggages[0].descriptions[0] :
                            ''}` }}
                        </b>
                        <b v-else>
                          {{ fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH','VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass).boardingBaggage }}
                          {{ fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH','VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass).boardingBaggage &&
                            fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH','VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass).checkinBaggage &&
                            fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH','VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass).checkinBaggage !== 'NONE' ? ' + ' : '' }}
                          <span
                            v-if="fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH','VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass).checkinBaggage !== 'NONE'"
                          >{{ fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH','VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass).checkinBaggage }}</span>
                        </b>
                      </span>
                    </div>

                    <!-- ANCHOR - Hoàn vé, hoàn bảo lưu -->
                    <div
                      v-if="['VN1A', 'VN1A_MT', 'QH'].includes(trip.source) || (['VJ'].includes(trip.source) && trip.domestic)"
                      class="mx-1"
                    >
                      <span v-if="trip.source !== 'VJ'">{{ $t('flight.refundTitle') }}: </span>
                      <span v-else>{{ $t('flight.refundTitleVJ') }}: </span>
                      <span
                        v-if="fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass)
                          ? fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass).refund !== 'NONE'
                          : !fareItem.noRefund
                        "
                        v-b-tooltip.hover="{ variant: 'success' }"
                        :title="fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass)
                          ? fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass).refund
                          : 'Được phép hoàn'
                        "
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          class="text-success"
                          size="16"
                        />
                      </span>

                      <span
                        v-else
                        v-b-tooltip.hover.v-danger
                        icon="TrelloIcon"
                        badge-classes="badge-info badge-glow"
                        title="Không được hoàn"
                      >
                        <feather-icon
                          icon="XCircleIcon"
                          class="text-danger"
                          size="16"
                        />
                      </span>
                    </div>

                    <!-- ANCHOR - Đổi lịch -->
                    <div v-if="['VN1A', 'VN1A_MT', 'QH'].includes(trip.source) || (['VJ'].includes(trip.source) && trip.domestic)">
                      <span>{{ $t('flight.Reschedule') }}: </span>
                      <span
                        v-if="fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass).reschedule !== 'NONE'"
                        v-b-tooltip.hover="{ variant: 'success' }"
                        badge-classes="badge-info badge-glow"
                        :title="fareRulesAirline(trip.segments[0].operating || trip.airline, ['QH', 'VU'].includes(trip.source) ? fareItem.fareType : fareItem.groupClass).reschedule"
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          class="text-success"
                          size="16"
                        />
                      </span>

                      <span
                        v-else
                        v-b-tooltip.hover.v-danger
                        icon="TrelloIcon"
                        badge-classes="badge-info badge-glow"
                        title="Không được đổi"
                      >
                        <feather-icon
                          icon="XCircleIcon"
                          class="text-danger"
                          size="16"
                        />
                      </span>
                    </div>
                  </b-row>
                </b-col>
              </div>
            </b-list-group-item>
          </div>
        </b-list-group>
      </b-tab>

      <!-- SECTION Flight Detail -->
      <b-tab
        :title-link-class="isMobileView ? 'px-25' : ''"
      >
        <template #title>
          <feather-icon icon="AlertCircleIcon" />
          <span>{{ $t('flight.flightDetails') }}</span>
        </template>

        <div
          v-for="(segment, indexSegment) in trip.segments"
          :key="indexSegment"
        >
          <div v-if="segment.flightLegInfoList && segment.flightLegInfoList.length > 0">
            <FlightDetail
              v-for="(seg, indexSeg) in segment.flightLegInfoList"
              :key="indexSeg"
              :class="indexSeg !== 0 ? 'border-top' : ''"
              :segment="{ ...segment, ...seg }"
              :trip="trip"
              :index="index"
              :index-segment="indexSegment"
              :operating="segment.operating"
              :airline="segment.airline"
              :flight-number="segment.flightNumber"
              :store-name="'app-reservation'"
              :selected-trip="selectedTrip?.id === trip.id ? selectedTrip : null"
            />
          </div>

          <FlightDetail
            v-else
            :segment="segment"
            :trip="trip"
            :index="index"
            :index-segment="indexSegment"
            :operating="segment.operating"
            :airline="segment.airline"
            :flight-number="segment.flightNumber"
            :store-name="'app-reservation'"
            :selected-trip="selectedTrip?.id === trip.id ? selectedTrip : null"
          />

          <!-- SECTION Giữa chặng -->
          <div v-if="indexSegment !== trip.segments.length - 1">
            <div v-if="segment.isNextAirport">
              <b-alert
                show
                variant="warning"
                class="py-50 px-1 shadow-sm d-flex align-items-center"
              >
                <feather-icon
                  icon="AlertTriangleIcon"
                  size="21"
                  class="text-warning mr-1"
                />
                <b>{{ $t('flight.alertNextAirport') }}</b>
              </b-alert>
            </div>
            <div v-if="segment.isNextDay">
              <b-alert
                show
                variant="warning"
                class="py-50 px-1 shadow-sm d-flex align-items-center"
              >
                <feather-icon
                  icon="AlertTriangleIcon"
                  size="21"
                  class="text-warning mr-1"
                />
                <b>{{ $t('flight.alertNextDay') }}</b>
              </b-alert>
            </div>
            <div v-if="segment.transitTime">
              <b-alert
                show
                variant="primary"
                class="py-50 px-1 shadow-sm d-flex align-items-center"
              >
                <feather-icon
                  icon="InfoIcon"
                  size="21"
                  class="text-primary mr-1"
                />
                <b>{{ $t('ticket.flightsInfo.transitTime') }}: {{ segment.transitTime }}</b>
              </b-alert>
            </div>
            <b-alert
              show
              variant="primary"
              class="py-50 px-1 shadow-sm d-flex align-items-center"
            >
              <feather-icon
                icon="InfoIcon"
                size="21"
                class="text-primary mr-1"
              />
              <b>{{ $t('flight.transitFlightInfo') }}</b>
            </b-alert>
          </div>
        </div>
      </b-tab>
    <!-- !SECTION -->

    </b-tabs>
  </div>
</template>

<script>
import {
  VBToggle,
  BAlert,
  VBTooltip,
  BListGroup,
  BListGroupItem,
  BCol,
  BRow,
  BTabs,
  BTab,
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ref,
  computed, watch,
} from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

import store from '@/store'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AirDataTimelineItem from '@core/components/app-timeline/AirDataTimelineItem.vue'
import {
  convertISODateTime,
  toHoursAndMinutes,
  formatCurrency,
  checkTransitAlert,
} from '@core/utils/filter'

import useReservationDetailBookingHandle from '@reservation/reservation-modify/components/detail/flights-details/useReservationDetailBookingHandle'

import useToast from '@useToast'

const resolveFarePrice = (flightSearch, fareItem) => [
  {
    title: 'ADT',
    quantity: flightSearch.adult,
    price: fareItem.afterMultiple.fareAdult,
    fare: fareItem.fareAdult,
    surcharge: fareItem.surchargeAdult,
    discount: fareItem.discountAdult,
    tax: fareItem.taxAdult,
    priceOne: fareItem.totalAdult,
  },
  {
    title: 'CHD',
    quantity: flightSearch.child,
    price: fareItem.afterMultiple.fareChild,
    fare: fareItem.fareChild,
    surcharge: fareItem.surchargeChild,
    discount: fareItem.discountChild,
    tax: fareItem.taxChild,
    priceOne: fareItem.totalChild,
  },
  {
    title: 'INF',
    quantity: flightSearch.infant,
    price: fareItem.afterMultiple.fareInfant,
    fare: fareItem.fareInfant,
    surcharge: fareItem.surchargeInfant,
    discount: fareItem.discountInfant,
    tax: fareItem.taxInfant,
    priceOne: fareItem.totalInfant,
  },
]

export default {
  components: {
    AppTimeline,
    AirDataTimelineItem,
    BAlert,
    BListGroup,
    BListGroupItem,
    BCol,
    BRow,
    BTabs,
    BTab,
    BCard,

    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
    FlightDetail: () => import('@/components/flight-detail/FlightDetail.vue'),
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },

  props: {
    trip: {
      type: Object,
      default: () => {},
    },
    flightSearch: {
      type: Object,
      default: () => {},
    },
    selectedTrip: {
      type: [Array, Object, undefined],
      default: () => {},
    },
    isAddFlight: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    indexItinerary: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      convertISODateTime,
      toHoursAndMinutes,
      formatCurrency,
      resolveFarePrice,
      farePrice: 0,
    }
  },
  computed: {
    tabIndex() {
      return this.$store.getters['app-reservation/getTabIndex']
    },
    selectedFlightSegment() {
      return this.$store.getters['app-reservation/getSelectedFlightSegment']
    },
    itineraryLength() {
      return this.$store.getters['app-reservation/getDataSearchItineraryLength']
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
  },

  created() {
    if (this.trip.segments.length > 0) {
      this.changeFarePrice(this.trip)
      this.checkAlertSegments(this.trip)
    }
  },
  methods: {
    changeFarePrice(data) {
      this.dataSegment = data.segments
      this.farePrice = data.fareOptions[0].totalAdult
    },
    checkAlertSegments(data) {
      for (let i = 0; i < data.segments.length - 1; i += 1) {
        const segment = data.segments[i]
        const nextSegment = data.segments[i + 1]
        const { isNextAirport, isNextDay, transitTime } = checkTransitAlert(segment.arrival, nextSegment.departure)
        Object.assign(segment, {
          isNextAirport, isNextDay, transitTime,
        })
      }
    },
    getAirCraftByIata(code) {
      return this.$store.getters['globalConfig/getAirCraftByIata'](code)
    },
    getAirportByAirportCode(airportCode) {
      return this.$store.getters['app-reservation/getAirportByAirportCode'](airportCode)
    },

    getAirlineNameByCode(code) {
      return this.$store.getters['globalConfig/getAirlineNameByCode'](code)
    },
    fareRulesAirline(airline, cabin) {
      return this.$store.getters['globalConfig/getFareRulesAirline'](airline, cabin)
    },
  },
  setup(props, { emit }) {
    const { toastError } = useToast()

    const ticketSelected = ref()
    const { itinerariesByIndexSelected } = useReservationDetailBookingHandle()
    function handleClickTicket(selectedFareOpt, index) {
      if (selectedFareOpt.seatAvailable === 0) {
        toastError({
          title: 'HẾT CHỖ!',
          content: 'Hạng vé đã chọn không còn chỗ, vui lòng chọn hạng vé khác!',
        })
        return
      }

      const selected = {
        ...props.trip,
        fareOptions: selectedFareOpt,
      }

      if (ticketSelected.value !== index) {
        ticketSelected.value = index
        emit('update:selectedTrip', cloneDeep(selected))
        store.dispatch('app-modify-flight/setSelectedTrip', { data: cloneDeep(selected), index: props.indexItinerary })
      } else if (ticketSelected.value === index) {
        ticketSelected.value = null
        emit('update:selectedTrip', null)
        store.dispatch('app-modify-flight/setSelectedTrip', { data: null, index: props.indexItinerary })
      }

      // this.changeFarePrice(selectedFareOpt)
    }

    const pricesFilter = computed(() => store.getters['app-reservation/getFilterByPrices'])
    const isRenderFareArray = ref([])
    // NOTE
    watch(pricesFilter, () => {
      try {
        isRenderFareArray.value = props.trip.fareOptions.map(item => !(pricesFilter.value.length && (item.afterMultiple.totalAdult < pricesFilter.value[0] || item.afterMultiple.totalAdult > pricesFilter.value[1])))
      } catch (error) {
        console.log(error)
      } finally {
        store.dispatch('app-reservation/setLoading', false)
      }
    }, { deep: true })

    function getFareOptions(listOptions, isVN1A) {
      if (isVN1A && !props.isAddFlight) {
        const getOldBookingClass = itinerariesByIndexSelected.value[0][0]?.bookingClass
        const indexOptionClass = getOldBookingClass ? listOptions.findIndex(f => f.bookingClass === getOldBookingClass) : -1
        return (indexOptionClass >= 0) ? listOptions.slice(indexOptionClass) : listOptions
      }
      return listOptions
    }

    return {
      ticketSelected,
      getFareOptions,
      handleClickTicket,
      isRenderFareArray,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.list-group::v-deep .list-group-item{
  border-radius: $border-radius-lg !important;
  border: none;
  &.active {
    background-color: rgba(47, 165, 229, 0.2) !important;
    color: $body-color !important;
    border: none !important;
    border-radius: $border-radius-lg !important;
      &:hover {
        background-color: rgba(47, 165, 229, 0.2) !important;
      }
      // &:focus {
      //   // color: $body-color;
      //   outline: 0;
      //   &:hover {
      //     color: $white;
      //   }
      // }
      // small {
      //   color: $white !important;
      // }
      &:hover {
      background-color: $body-bg;
    }
  }
  &:hover{
    transition: 0.2s !important;
    scale: 1.002 !important;
  }
}
</style>
