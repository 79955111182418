var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    staticClass: "text-right d-flex flex-column justify-content-between",
    attrs: {
      "cols": _vm.isMobileView ? '3' : '2'
    }
  }, [_c('div', [_vm._t("left")], 2), _c('div', {
    staticClass: "mb-3 text-muted"
  }, [_vm._t("bottom-left")], 2)]), _c('b-col', [_c('li', {
    staticClass: "timeline-item",
    class: ["timeline-variant-".concat(_vm.variant), _vm.fillBorder ? "timeline-item-fill-border-".concat(_vm.variant) : null, _vm.noBorder ? 'no-border-item' : null, "py-0", _vm.isMobileView ? 'pl-2' : '']
  }, [!_vm.icon ? _c('div', {
    staticClass: "timeline-item-point"
  }) : _c('div', {
    staticClass: "timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"
  }, [_c('feather-icon', {
    attrs: {
      "icon": _vm.icon,
      "size": "24"
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column justify-content-between"
  }, [_c('div', [_vm._t("right")], 2), _c('div', {
    staticClass: "my-1"
  }, [_vm._t("bottom-right")], 2)])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }