var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-collapse', {
    attrs: {
      "accordion": ""
    }
  }, [!_vm.isEmpty(_vm.itinerary) ? _c('div', [_c('div', _vm._l(_vm.itinerary, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('b-card', {
      staticClass: "mb-50",
      staticStyle: {
        "border-radius": "8px",
        "box-shadow": "0px 4px 6px rgba(0, 0, 0, 0.05)"
      },
      attrs: {
        "no-body": ""
      }
    }, [_c('app-collapse-flight', {
      attrs: {
        "item": item,
        "index": index,
        "selected-trip": _vm.selectedTripTemp
      },
      on: {
        "update:selectedTrip": function updateSelectedTrip($event) {
          _vm.selectedTripTemp = $event;
        },
        "update:selected-trip": function updateSelectedTrip($event) {
          _vm.selectedTripTemp = $event;
        }
      }
    }, [_c('BookingDetailTable', {
      attrs: {
        "flight-search": _vm.flightSearch,
        "trip": item,
        "index": index,
        "index-itinerary": _vm.indexItinerary,
        "is-add-flight": _vm.isAddFlight,
        "selected-trip": _vm.selectedTripTemp
      },
      on: {
        "update:selectedTrip": function updateSelectedTrip($event) {
          _vm.selectedTripTemp = $event;
        },
        "update:selected-trip": function updateSelectedTrip($event) {
          _vm.selectedTripTemp = $event;
        }
      }
    })], 1)], 1)], 1);
  }), 0)]) : _c('b-alert', {
    staticClass: "mb-0 p-1",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.notBookingFound')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }