<template>
  <app-collapse accordion>
    <div v-if="!isEmpty(itinerary)">
      <!-- <b-alert
        v-if="(processValue || processValue === 0)"
        show
        variant="info"
        class="mb-0 p-1"
      >
        Đang tìm kiếm...
      </b-alert> -->

      <!-- <b-alert
        v-else-if="!(processValue || processValue === 0)"
        show
        variant="warning"
        class="mb-0 p-1"
      >
        {{ $t('flight.notBookingFound') }}
      </b-alert> -->

      <div>
        <div
          v-for="(item, index) in itinerary"
          :key="index"
        >
          <b-card
            class="mb-50"
            style="border-radius: 8px;  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);"
            no-body
          >
            <app-collapse-flight
              :item="item"
              :index="index"
              :selected-trip.sync="selectedTripTemp"
            >
              <BookingDetailTable
                :flight-search="flightSearch"
                :trip="item"
                :index="index"
                :index-itinerary="indexItinerary"
                :is-add-flight="isAddFlight"
                :selected-trip.sync="selectedTripTemp"
              />
            </app-collapse-flight>
          </b-card>
        </div>
      </div>
    </div>

    <b-alert
      v-else
      show
      variant="warning"
      class="mb-0 p-1"
    >
      {{ $t('flight.notBookingFound') }}
    </b-alert>
  </app-collapse>
</template>

<script>
import {
  BCard, BAlert,
} from 'bootstrap-vue'
import {
  watch, ref,
} from '@vue/composition-api'
import { cloneDeep, isEmpty } from 'lodash'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'

import useReservationDetailBookingHandle from '@reservation/reservation-modify/components/detail/flights-details/useReservationDetailBookingHandle'

import AppCollapseFlight from './AppCollapseFlight.vue'
import BookingDetailTable from './BookingDetailTable.vue'

export default {
  components: {
    BCard,
    BAlert,
    AppCollapse,
    AppCollapseFlight,
    BookingDetailTable,
  },
  props: {
    itinerary: {
      type: Array,
      default: () => [],
    },
    selectedTrip: {
      type: [Array, Object, undefined],
      default: () => {},
    },
    indexItinerary: {
      type: Number,
      default: null,
    },
    isAddFlight: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      flightSearch,
    } = useReservationDetailBookingHandle()

    const selectedTripTemp = ref()

    watch(selectedTripTemp, () => {
      emit('update:selectedTrip', cloneDeep(selectedTripTemp.value))
    })

    return {
      flightSearch,
      selectedTripTemp,
      isEmpty,
    }
  },
}
</script>
