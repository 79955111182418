var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-1"
  }, [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "title-link-class": _vm.isMobileView ? 'px-25 mr-75' : ''
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "FileTextIcon"
          }
        }), _c('span', [_vm._v(_vm._s(_vm.$t('flight.fare&benefit')))])];
      },
      proxy: true
    }])
  }, [_c('b-list-group', {
    staticClass: " cursor-pointer",
    attrs: {
      "flush": ""
    }
  }, _vm._l(_vm.getFareOptions(_vm.trip.fareOptions, ['VN1A_MT', 'VN1A'].includes(_vm.trip.source)), function (fareItem, i) {
    return _c('div', {
      key: i
    }, [_c('b-list-group-item', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isRenderFareArray.length ? _vm.isRenderFareArray[i] : true,
        expression: "isRenderFareArray.length ? isRenderFareArray[i] : true"
      }],
      class: "border-bottom ".concat(_vm.currentBreakPoint !== 'xl' ? 'font-small-3 px-25' : ''),
      attrs: {
        "active": _vm.ticketSelected === i,
        "disabled": fareItem.seatAvailable === 0
      },
      on: {
        "click": function click($event) {
          return _vm.handleClickTicket(fareItem, i);
        }
      }
    }, [_c('div', {
      class: "d-flex pb-50"
    }, [_c('b-col', {
      staticClass: "d-flex p-0 align-items-center justify-content-between",
      attrs: {
        "cols": "1",
        "md": "1"
      }
    }, [_vm.ticketSelected !== i ? _c('feather-icon', {
      staticClass: "mt-50",
      attrs: {
        "icon": "CircleIcon",
        "size": "21"
      }
    }) : _vm._e(), _vm.ticketSelected === i ? _c('feather-icon', {
      staticClass: "text-success mt-50",
      attrs: {
        "icon": "CheckCircleIcon",
        "size": "21"
      }
    }) : _vm._e()], 1), _c('b-col', {
      staticClass: "font-weight-bold pl-0",
      attrs: {
        "cols": "11",
        "md": "11"
      }
    }, [_c('b-row', {
      staticClass: "mb-1"
    }, [_c('b-col', {
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('div', {
      staticClass: "font-weight-bolderp"
    }, [_vm.trip.source !== 'VJ' && _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? fareItem.fareType : fareItem.groupClass) ? _c('span', [_vm._v(" " + _vm._s(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? fareItem.fareType : fareItem.groupClass).cabinName) + " ")]) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(['QH', 'VU', '1S', '1S_CTRL'].includes(_vm.trip.source) ? '' : "".concat(fareItem.groupClass)) + " (" + _vm._s(fareItem.bookingClass) + ") ")])])]), _c('b-col', {
      attrs: {
        "cols": "12",
        "md": "8"
      }
    }, _vm._l(_vm.resolveFarePrice(_vm.flightSearch, fareItem), function (priceItem, indexPrice) {
      return _c('div', {
        key: indexPrice
      }, [priceItem.price > 0 ? _c('b-row', [_c('b-col', {
        staticClass: "text-center p-0",
        attrs: {
          "cols": "3"
        }
      }, [_c('span', [_vm._v(_vm._s(priceItem.title))])]), _c('b-col', {
        staticClass: "text-right pl-0",
        attrs: {
          "cols": "3"
        }
      }, [_c('span', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.v-primary",
          modifiers: {
            "hover": true,
            "v-primary": true
          }
        }],
        staticClass: "text-nowrap",
        attrs: {
          "badge-classes": "badge-info badge-glow",
          "title": "Giá vé"
        }
      }, [_vm._v(" " + _vm._s(_vm.formatCurrency(priceItem.fare)) + " ")])]), _c('b-col', {
        staticClass: "text-right pl-0",
        attrs: {
          "cols": "3"
        }
      }, [_c('span', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.v-primary",
          modifiers: {
            "hover": true,
            "v-primary": true
          }
        }],
        staticClass: "text-nowrap",
        attrs: {
          "badge-classes": "badge-info badge-glow text-nowrap",
          "title": "Thu\u1EBF: ".concat(_vm.formatCurrency(priceItem.tax), " - Ph\xED: ").concat(_vm.formatCurrency(priceItem.surcharge), " - Chi\u1EBFt kh\u1EA5u: ").concat(_vm.formatCurrency(priceItem.discount))
        }
      }, [_vm._v(" " + _vm._s(_vm.formatCurrency(priceItem.tax + priceItem.surcharge - priceItem.discount)) + " ")])]), _c('b-col', {
        staticClass: "text-right font-weight-bolder pl-0",
        attrs: {
          "cols": "3"
        }
      }, [_c('span', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.v-primary",
          modifiers: {
            "hover": true,
            "v-primary": true
          }
        }],
        staticClass: "text-nowrap",
        attrs: {
          "badge-classes": "badge-info badge-glow",
          "title": "Tổng tiền"
        }
      }, [_vm._v(" " + _vm._s(_vm.formatCurrency(priceItem.priceOne)) + " ")])])], 1) : _vm._e()], 1);
    }), 0)], 1), _c('b-row', {
      staticClass: "mx-0"
    }, [_c('div', {
      staticClass: "d-flex align-items-center justify-content-center px-md-1"
    }, [_c('span', {
      staticClass: "mr-1 text-nowrap"
    }, [_vm._v(" " + _vm._s(_vm.$t('flight.seatAvailable')) + ": "), _c('b', {
      class: fareItem.seatAvailable > 0 ? 'text-airline fw-700' : 'text-danger fw-700'
    }, [_vm._v(_vm._s(fareItem.seatAvailable > 0 ? "".concat(fareItem.seatAvailable) : 'HẾT CHỖ'))])]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.Baggage')) + ": "), _vm.trip.source === '1G' ? _c('b', [_vm._v(" " + _vm._s("".concat(fareItem.baggages[0].numBaggagePieces > 0 ? fareItem.baggages[0].numBaggagePieces + 'PC' : '').concat(fareItem.baggages[0].numBaggagePieces > 1 ? 's' : '', " ").concat(fareItem.baggages[0].numBaggagePieces && fareItem.baggages[0].descriptions.length ? ' + ' : '', " ").concat(fareItem.baggages[0].descriptions.length ? fareItem.baggages[0].descriptions[0] : '')) + " ")]) : _c('b', [_vm._v(" " + _vm._s(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? fareItem.fareType : fareItem.groupClass).boardingBaggage) + " " + _vm._s(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? fareItem.fareType : fareItem.groupClass).boardingBaggage && _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? fareItem.fareType : fareItem.groupClass).checkinBaggage && _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? fareItem.fareType : fareItem.groupClass).checkinBaggage !== 'NONE' ? ' + ' : '') + " "), _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? fareItem.fareType : fareItem.groupClass).checkinBaggage !== 'NONE' ? _c('span', [_vm._v(_vm._s(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? fareItem.fareType : fareItem.groupClass).checkinBaggage))]) : _vm._e()])])]), ['VN1A', 'VN1A_MT', 'QH'].includes(_vm.trip.source) || ['VJ'].includes(_vm.trip.source) && _vm.trip.domestic ? _c('div', {
      staticClass: "mx-1"
    }, [_vm.trip.source !== 'VJ' ? _c('span', [_vm._v(_vm._s(_vm.$t('flight.refundTitle')) + ": ")]) : _c('span', [_vm._v(_vm._s(_vm.$t('flight.refundTitleVJ')) + ": ")]), (_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? fareItem.fareType : fareItem.groupClass) ? _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? fareItem.fareType : fareItem.groupClass).refund !== 'NONE' : !fareItem.noRefund) ? _c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: {
          variant: 'success'
        },
        expression: "{ variant: 'success' }",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "title": _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? fareItem.fareType : fareItem.groupClass) ? _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? fareItem.fareType : fareItem.groupClass).refund : 'Được phép hoàn'
      }
    }, [_c('feather-icon', {
      staticClass: "text-success",
      attrs: {
        "icon": "DollarSignIcon",
        "size": "16"
      }
    })], 1) : _c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.v-danger",
        modifiers: {
          "hover": true,
          "v-danger": true
        }
      }],
      attrs: {
        "icon": "TrelloIcon",
        "badge-classes": "badge-info badge-glow",
        "title": "Không được hoàn"
      }
    }, [_c('feather-icon', {
      staticClass: "text-danger",
      attrs: {
        "icon": "XCircleIcon",
        "size": "16"
      }
    })], 1)]) : _vm._e(), ['VN1A', 'VN1A_MT', 'QH'].includes(_vm.trip.source) || ['VJ'].includes(_vm.trip.source) && _vm.trip.domestic ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('flight.Reschedule')) + ": ")]), _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? fareItem.fareType : fareItem.groupClass).reschedule !== 'NONE' ? _c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: {
          variant: 'success'
        },
        expression: "{ variant: 'success' }",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "badge-classes": "badge-info badge-glow",
        "title": _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? fareItem.fareType : fareItem.groupClass).reschedule
      }
    }, [_c('feather-icon', {
      staticClass: "text-success",
      attrs: {
        "icon": "DollarSignIcon",
        "size": "16"
      }
    })], 1) : _c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.v-danger",
        modifiers: {
          "hover": true,
          "v-danger": true
        }
      }],
      attrs: {
        "icon": "TrelloIcon",
        "badge-classes": "badge-info badge-glow",
        "title": "Không được đổi"
      }
    }, [_c('feather-icon', {
      staticClass: "text-danger",
      attrs: {
        "icon": "XCircleIcon",
        "size": "16"
      }
    })], 1)]) : _vm._e()])], 1)], 1)])], 1);
  }), 0)], 1), _c('b-tab', {
    attrs: {
      "title-link-class": _vm.isMobileView ? 'px-25' : ''
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "AlertCircleIcon"
          }
        }), _c('span', [_vm._v(_vm._s(_vm.$t('flight.flightDetails')))])];
      },
      proxy: true
    }])
  }, _vm._l(_vm.trip.segments, function (segment, indexSegment) {
    var _vm$selectedTrip2;
    return _c('div', {
      key: indexSegment
    }, [segment.flightLegInfoList && segment.flightLegInfoList.length > 0 ? _c('div', _vm._l(segment.flightLegInfoList, function (seg, indexSeg) {
      var _vm$selectedTrip;
      return _c('FlightDetail', {
        key: indexSeg,
        class: indexSeg !== 0 ? 'border-top' : '',
        attrs: {
          "segment": Object.assign({}, segment, seg),
          "trip": _vm.trip,
          "index": _vm.index,
          "index-segment": indexSegment,
          "operating": segment.operating,
          "airline": segment.airline,
          "flight-number": segment.flightNumber,
          "store-name": 'app-reservation',
          "selected-trip": ((_vm$selectedTrip = _vm.selectedTrip) === null || _vm$selectedTrip === void 0 ? void 0 : _vm$selectedTrip.id) === _vm.trip.id ? _vm.selectedTrip : null
        }
      });
    }), 1) : _c('FlightDetail', {
      attrs: {
        "segment": segment,
        "trip": _vm.trip,
        "index": _vm.index,
        "index-segment": indexSegment,
        "operating": segment.operating,
        "airline": segment.airline,
        "flight-number": segment.flightNumber,
        "store-name": 'app-reservation',
        "selected-trip": ((_vm$selectedTrip2 = _vm.selectedTrip) === null || _vm$selectedTrip2 === void 0 ? void 0 : _vm$selectedTrip2.id) === _vm.trip.id ? _vm.selectedTrip : null
      }
    }), indexSegment !== _vm.trip.segments.length - 1 ? _c('div', [segment.isNextAirport ? _c('div', [_c('b-alert', {
      staticClass: "py-50 px-1 shadow-sm d-flex align-items-center",
      attrs: {
        "show": "",
        "variant": "warning"
      }
    }, [_c('feather-icon', {
      staticClass: "text-warning mr-1",
      attrs: {
        "icon": "AlertTriangleIcon",
        "size": "21"
      }
    }), _c('b', [_vm._v(_vm._s(_vm.$t('flight.alertNextAirport')))])], 1)], 1) : _vm._e(), segment.isNextDay ? _c('div', [_c('b-alert', {
      staticClass: "py-50 px-1 shadow-sm d-flex align-items-center",
      attrs: {
        "show": "",
        "variant": "warning"
      }
    }, [_c('feather-icon', {
      staticClass: "text-warning mr-1",
      attrs: {
        "icon": "AlertTriangleIcon",
        "size": "21"
      }
    }), _c('b', [_vm._v(_vm._s(_vm.$t('flight.alertNextDay')))])], 1)], 1) : _vm._e(), segment.transitTime ? _c('div', [_c('b-alert', {
      staticClass: "py-50 px-1 shadow-sm d-flex align-items-center",
      attrs: {
        "show": "",
        "variant": "primary"
      }
    }, [_c('feather-icon', {
      staticClass: "text-primary mr-1",
      attrs: {
        "icon": "InfoIcon",
        "size": "21"
      }
    }), _c('b', [_vm._v(_vm._s(_vm.$t('ticket.flightsInfo.transitTime')) + ": " + _vm._s(segment.transitTime))])], 1)], 1) : _vm._e(), _c('b-alert', {
      staticClass: "py-50 px-1 shadow-sm d-flex align-items-center",
      attrs: {
        "show": "",
        "variant": "primary"
      }
    }, [_c('feather-icon', {
      staticClass: "text-primary mr-1",
      attrs: {
        "icon": "InfoIcon",
        "size": "21"
      }
    }), _c('b', [_vm._v(_vm._s(_vm.$t('flight.transitFlightInfo')))])], 1)], 1) : _vm._e()], 1);
  }), 0)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }